import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default defineNuxtPlugin({
  name: 'date-picker',
  parallel: true,
  async setup(nuxtApp) {
    nuxtApp.vueApp.component('VueDatePicker', VueDatePicker);
  },
});
