import revive_payload_client_tB7klOzbVk from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_dzjzk4hbiyyea2ot73tbp4quqi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_sSewZ0Agv5 from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_dzjzk4hbiyyea2ot73tbp4quqi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_luDVP63bdi from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_dzjzk4hbiyyea2ot73tbp4quqi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_vV6yrv4eZs from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_dzjzk4hbiyyea2ot73tbp4quqi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_urzTFN5owt from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_dzjzk4hbiyyea2ot73tbp4quqi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8zrSMzsPsI from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_dzjzk4hbiyyea2ot73tbp4quqi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SYFce7tjM8 from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_dzjzk4hbiyyea2ot73tbp4quqi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_jEpRIyIQxy from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.30.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_w4JjfgI9Ph from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_dzjzk4hbiyyea2ot73tbp4quqi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_EIcJ7xiw0h from "/app/.nuxt/floating-vue.mjs";
import switch_locale_path_ssr_sOXyA866ut from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.30.1_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_pe2eoexaYA from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.30.1_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import date_picker_TQt8pdx8DF from "/app/layers/shared/plugins/date-picker.ts";
import dom_purify_html_client_jzZFfRKTcu from "/app/layers/shared/plugins/dom-purify-html.client.ts";
import supertokens_client_oFy7K1e5JT from "/app/core/plugins/supertokens.client.ts";
export default [
  revive_payload_client_tB7klOzbVk,
  unhead_sSewZ0Agv5,
  router_luDVP63bdi,
  payload_client_vV6yrv4eZs,
  navigation_repaint_client_urzTFN5owt,
  check_outdated_build_client_8zrSMzsPsI,
  chunk_reload_client_SYFce7tjM8,
  plugin_vue3_jEpRIyIQxy,
  components_plugin_KR1HBZs4kY,
  prefetch_client_w4JjfgI9Ph,
  floating_vue_EIcJ7xiw0h,
  switch_locale_path_ssr_sOXyA866ut,
  i18n_pe2eoexaYA,
  date_picker_TQt8pdx8DF,
  dom_purify_html_client_jzZFfRKTcu,
  supertokens_client_oFy7K1e5JT
]