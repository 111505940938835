import SuperTokens from 'supertokens-web-js';
import Session from 'supertokens-web-js/recipe/session';
import EmailPassword from 'supertokens-web-js/recipe/emailpassword';

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  const app = SuperTokens.init({
    appInfo: {
      appName: 'Solara',
      apiDomain: config.public.AUTH_API_URL as string,
      apiBasePath: '/auth',
    },
    recipeList: [
      Session.init({
        sessionTokenFrontendDomain: config.public.AUTH_FRONTEND_DOMAIN,
        tokenTransferMethod: 'header',
      }),
      EmailPassword.init(),
    ],
  });

  return {
    provide: {
      authApp: app,
    },
  };
});
